<template>
  <!--控制显示对应的子页面-->
  <div class="ll_content" style="font-family: Alibaba_PuHuiTi_P3">
    <!-- <view class="info" style="font-size: 40rpx;">测试PPPPPP</view> -->
    <!--960以下的窄屏-->
    <MyPagePhone
      :pageData="pageData"
      v-on:appOpen="appOpen"
      v-if="isWidth == 1"
    />
    <!--PC宽屏-->
    <MyPagePc
      :pcScale="pcScale"
      :pageData="pageData"
      @appOpen="appOpen"
      v-else-if="isWidth == 2"
    />
  </div>
</template>

<script>
//路由
// import { useRouter } from "vue-router";
//手机子页面
import MyPagePhone from "./my-page-phone.vue";
//pc端子页面
import MyPagePc from "./my-page-pc.vue";

import axios from "axios";
var that;
export default {
  name: "App",
  data() {
    return {
      myWidth: 960, //以960px为单位区分是小屏还是大屏
      isWidth: 0, //1表示手机屏幕，2表示宽屏
      //   style="transform: scale(0.5)"
      pcScale: {
        scale: 1, //缩放级别 ，根据屏幕分辨率动态设置
        ratioX: 0,
        ratioY: 0,
      },

      pageData: {
        //pc端字体适配规则
        // 1类字体：50px例：公益模式
        // 2类字体：35px例：9.55亿元
        // 3类字体：22px 例 ：Android版下载
        // 4类字体：16px 小字内容例：展示联盟单位
        // 5类字体：14px 例：京ICP备17036

        //移动端字体适配规则
        // 1类字体：0.50rem例：公益模式
        // 2类字体：0.35rem例：9.55亿元
        // 3类字体：0.22rem 并加粗 例 ：Android版下载
        // 4类字体：0.22rem 小字内容例：展示联盟单位
        // 5类字体：0.16rem 例：京ICP备17036

        zhonghangTitle: "中行动态", // 4类字体
        lianxiInfo: "联系我们:400-666-7308", // 4类字体
        agreement_1: "《用户协议》", // 5类字体
        agreement_2: "《隐私协议》", // 5类字体
        company_1: "©2018 中益善源（北京）科技有限公司", // 5类字体
        ICP_info: "京ICP备17036374号-1", // 5类字体
        beian_info: "京公网安备 11010802024561号", // 5类字体
        bottom_info: "平台客服电话：400-666-7308", // 5类字体

        download_1: "Android版下载", // 3类字体
        download_2: "iOS版下载", // 3类字体
        download_3: "微信小程序", // 3类字体

        gongyiTitle: "公益模式", //1类字体
        gongyiTitleDesc: "MODEL", // 3类字体
        gongyiData: [
          //公益数据列表
          {
            logo: require("../assets/images/moder_icon1.png"),
            name: "公益联盟", // 3类字体
            desc: "帮助各部委、央企及高校院所或地方政府、以及其他企业搭建消费帮扶子平台。", // 4类字体
          },
          {
            logo: require("../assets/images/moder_icon2.png"),
            name: "定点帮扶",
            desc: "展示联盟单位对口帮扶地区产品，为帮扶产品供货商提供专业可靠的电子商务平台。",
          },
          {
            logo: require("../assets/images/moder_icon3.png"),
            name: "善源集采",
            desc: "各单位集中采购通道，可以满足员工福利/食堂用料/营销物料等采购需求。",
          },
          {
            logo: require("../assets/images/moder_icon4.png"),
            name: "积分商城",
            desc: "让积分更有价值，为回馈爱心帮扶人士而设置的商城,可进行积分兑换、积分加价购等。",
          },
        ], // 数据列表

        bangfuTitle: "平台帮扶业绩", //1类字体
        bangfuTitleDesc: "ACHIEVEMENT", // 3类字体
        bangfuData: [
          {
            id: "ref_bangfu_1", //数据源加动画用
            name: "22个", // 2类字体
            desc: "服务公益联盟", // 3类字体
          },
          {
            id: "ref_bangfu_2", //数据源加动画用
            name: "522万人",
            desc: "注册用户",
          },
          {
            id: "ref_bangfu_3", //数据源加动画用
            name: "283个",
            desc: "覆盖帮扶县域",
          },
          {
            id: "ref_bangfu_4", //数据源加动画用
            name: "4.85万款",
            desc: "累计上架产品",
          },
          {
            id: "ref_bangfu_5", //数据源加动画用
            name: "12.69亿元",
            desc: "累计消费帮扶金额",
          },
        ],
        bangfuDesc: "以上数据统计截至2024年12月", // 4类字体

        pingtaiTitle: "平台优势", //1类字体
        pingtaiTitleDesc: "ADVANTAGE", // 3类字体
        pingtaiData: [
          {
            logo: require("../assets/images/ic_pc_p_1.png"),
            name: "政策利好", // 3类字体
            desc: "贯彻党的二十大精神，借鉴脱贫攻坚经验，全面推进乡村振兴，紧密结合新形势新任务进行创造性转化和创新性发展，走好中国特色乡村振兴之路。", // 4类字体
          },
          {
            logo: require("../assets/images/ic_pc_p_2.png"),
            name: "市场力量",
            desc: "随着832个脱贫县、160个国家乡村振兴重点帮扶县、中央国家机关及相关单位定点对口支援帮扶地区等帮扶地区企业持续入驻，平台已形成“帮扶显著、地方特产、物美价廉”的帮扶特色。",
          },
          {
            logo: require("../assets/images/ic_pc_p_3.png"),
            name: "科技赋能",
            desc: "运用“互联网+乡村振兴”思维，同时借助中国银行金融服务优势，为平台融入多项前沿技术。把“中银善源”平台建设成为高效、公开、透明、公平的公益大数据平台，成为服务农村市场、促进乡村振兴的专业平台。",
          },
        ],

        lianmengTitle: "公益联盟", //1类字体
        lianmengTitleDesc: "COOPERATE", // 3类字体
        lianmengData_1_title: "中央和国家机关", // 4类字体
        lianmengData_2_title: "央企", // 4类字体
        lianmengData_1: [
          {
            logo_big: require("../assets/images/ic_pc_renda.jpg"),
            name: "全国人大机关", // 4类字体
          },
          {
            logo_big: require("../assets/images/ic_pc_zhongren.jpg"),
            name: "中国人民银行",
          },
          {
            logo_big: require("../assets/images/ic_pc_wenlv.jpg"),
            name: "中华人民共和国文化和旅游部",
          },
          {
            logo_big: require("../assets/images/ic_pc_zhujian.jpg"),
            name: "公益住建",
          },
          {
            logo_big: require("../assets/images/ic_pc_zhongshe.jpg"),
            name: "中国社会科学院",
          },
          {
            logo_big: require("../assets/images/ic_more.jpg"),
            name: "...",
          },
        ],
        lianmengData_2: [
          {
            logo_big: require("../assets/images/ic_pc_zhonggu.jpg"),
            name: "中国银行股份有限公司", // 4类字体
          },
          {
            logo_big: require("../assets/images/ic_pc_zhongbing.jpg"),
            name: "中国兵器工业集团有限公司",
          },
          {
            logo_big: require("../assets/images/ic_pc_zhongshang.jpg"),
            name: "中国商用飞机有限责任公司",
          },
          {
            logo_big: require("../assets/images/ic_pc_zhongjin.jpg"),
            name: "中国进出口银行",
          },
          {
            logo_big: require("../assets/images/ic_pc_zhonghe.jpg"),
            name: "公益中核",
          },
          {
            logo_big: require("../assets/images/ic_more.jpg"),
            name: "...",
          },
        ],

        aboutTitle: "关于我们", //1类字体
        aboutTitleDesc: "OUR TEAM", // 3类字体
        //4类字体
        aobutDesc:
          "“中银善源”平台（原“公益中国”平台）是中国银行运用“互联网+乡村振兴”思维，融合政策利好、市场力量、科技赋能，自主开发的消费帮扶共享平台，是实现农产品产销对接的直通平台。可作为部委、企业、地方政府及高校院所开展消费帮扶，推进乡村振兴工作的管理工具。",
      },
    };
  },
  components: {
    MyPagePhone,
    MyPagePc,
  },
  methods: {
    // 判断是否是手机端，如果是，返回true
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    resetRem() {
      // 基准大小
      //   const baseSize = 16;
      // 设置 rem 函数
      function setRem() {
 

        if (window.innerWidth < that.myWidth) {
          //手机端
          that.isWidth = 1;
        } else {
          that.isWidth = 2;
          // if (window.innerWidth < 800) {
          //   that.pcScale = 0.5;
          // } else if (window.innerWidth < 1400) {
          //   that.pcScale = 0.4;
          // } else if (window.innerWidth < 3000) {
          //   that.pcScale = 1;
          // } else { //超过3000
          //   that.pcScale = 1.2;
          // }
          // 1:1340
          // 0:0
          // x:window.innerWidth
          // const dpr = window.devicePixelRatio || 1;
          //根据最小宽度1340来算比例 1
          // var minWidth = 1400;
          // var minHeight = 5000;
          // var ratioX = (window.innerWidth * 1) / minWidth;
          // // var ratioY = (document.documentElement.scrollHeight * 1) / minHeight;

          // document.body.style.transform =
          //   "scale(" + ratioX + "," + 1 + ")";
          // console.info(
          //             "document.documentElement.scrollHeight",
          //             document.documentElement.scrollHeight
          //           );
          // if (window.innerWidth < 800) {
          //   that.pcScale.ratioX = 0.5;
          // } else if (window.innerWidth < 1000) {
          //   that.pcScale.ratioX = 0.6;
          // } else if (window.innerWidth < 1200) {
          //   that.pcScale.ratioX = 0.85;
          // } else if (window.innerWidth < 1300) {
          //   that.pcScale.ratioX = 0.90;
          // } else if (window.innerWidth < 1400) {
          //   that.pcScale.ratioX = 1;
          // } else if (window.innerWidth < 2000) {
          //   that.pcScale.ratioX = 1;
          // } else if (window.innerWidth < 3000) {
          //   that.pcScale.ratioX = 1.1;
          // } else {
          //   that.pcScale.ratioX = 1.5;
          // }
          // document.body.style.transform = "scale(" + that.pcScale.ratioX + ")";
          // document.body.style.transformOrigin = "top center";

          //       :style="
          //   'transform: scale(' +
          //   pcScale.ratioX +
          //   ');transform-origin: center 0;'
          // "
          // console.info("that.pcScale", that.pcScale);
          // // console.info("==dpr", dpr);
          // console.info("======",window.devicePixelRatio);

          // 1920 代表当前页面的宽度，可动态获取；
          // var ratioX = window.innerWidth / minWidth;
          // // 1080 代表当前页面的高度，可动态获取；
          // var ratioY = window.innerHeight / 1080;
          // that.pcScale.ratioX = ratioX * ratioX;
          // that.pcScale.ratioY = ratioY;
          // pc端适配方案一共四套标准。
          // 【1】0~960px像素
          // 用手机端设计图，等比缩放内容
          // 【2】960~1400px像素
          // 用PC端设计图，关键列表信息等比缩放，其它如标题大小保持不变。
          // 【3】1400~3000px像素
          // 用PC端设计图，所有大小都保持不变，两边会随着收束而看不见。
          // 【4】大于3000像素
          // 用PC端设计图，整个设计图大小固定为1.2倍（实测在大的话图片就失真了）

          if (window.innerWidth < 1400) {
            that.pcScale.scale = (window.innerWidth * 1) / 1400;
          } else if (window.innerWidth < 3000) {
            that.pcScale.scale = 1;
          } else {
            that.pcScale.scale = 1.2;
            // document.body.style.transform = "scale(" + 1+ ")";
          }
          // that.pcScale.scale = 0.5
        }

        // const docEl = document.documentElement;
        // const clientWidth = docEl.clientWidth;
        // const dpr = window.devicePixelRatio || 1;
        // docEl.setAttribute("data-dpr", dpr);
        // docEl.style.fontSize = `${clientWidth * dpr / 19}px`
        // if (window.innerWidth < that.myWidth) {
        // 当前页面宽度相对于 X宽的缩放比例，可根据自己需要修改。
        const scale = (document.documentElement.clientWidth / 750) * 100; //设计图是750,则宽度为7.5rem
        // 设置页面根节点字体大小
        // console.info("scale", scale); 
        // if(){//当前是主页

        // }else if(){//当前是协议页

        // }
        document.documentElement.style.fontSize = scale + "px"; 
        // } else {
        //   document.documentElement.style.fontSize = baseSize + "px";
        // }
      }
      // 初始化
      setRem();
      // 改变窗口大小时重新设置 rem
      window.onresize = function () {
        that.isWidth = window.innerWidth < that.myWidth ? 1 : 2;
        setRem();
      };
    },
    appOpen(type, value) {
      // console.info("appOpen type-->", type);
      // console.info("appOpen value-->", value);
      if (type == "android_download") {
        var url =
          this.$getApiHost() +
          "/union-center/union/check?channel=17sy&device=android";
        axios
          .get(url, {
            // params: {
            // },
          })
          .then(function (sres) {
            // console.info("== requestUpdateInfo succ res", sres);
            if (sres.data.code == "200") {
              // window.open(sres.data.content.updateUrl, "_blank");
              window.location.href = sres.data.content.updateUrl;
            }
          })
          .catch(function (err) {
            // that.$myLog("== catch ", err);
            console.info("== requestUpdateInfo fail err", err);
          });
      } else if (type == "ios_download") {
        //ios下载
        // window.open("https://apps.apple.com/cn/app/id1240761571", "_blank");
        window.location.href = "https://apps.apple.com/cn/app/id1240761571";
      } else if (type == "mini_download") {
        //小程序点击
        var urlType = 1; //1:普通浏览器scheme链接，2：微信浏览器url link
        if (
          navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1
        ) {
          //当前是【微信浏览器】
          urlType = 2;
        } else if (
          that.isWidth == 1 &&
          navigator.userAgent.toLowerCase().indexOf("micromessenger") === -1
        ) {
          //当前是【普通浏览器】【手机环境】
          urlType = 1;
        } else {
          //其它环境不触发去下载
          return;
        }
        var urlMini =
          this.$getApiHost() + "/user-center/wxApi/getLink?type=" + urlType;
        axios
          .get(urlMini, {
            // params: {
            // },
          })
          .then(function (sres) {
            // console.info("== requestUpdateInfo succ res", sres);
            if (sres.data.code == "200") {
              // window.open(sres.data.content, "_blank");
              // console.info('====',sres.data.content)
              window.location.href = sres.data.content; //小程序链接在ios手机，只能用href不能用open
            }
          })
          .catch(function (err) {
            // that.$myLog("== catch ", err);
            console.info("== requestUpdateInfo fail err", err);
          });
      } else if (type == "zhong_hang") {
        //中行落地页
        // window.open("https://www.boc.cn/aboutboc/bi1/", "_blank");
        window.location.href = "https://www.boc.cn/aboutboc/bi1/";
      } else if (type == "beian_gov") {
        //底部 京公网备案
        // window.open(
        //   "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802024561",
        //   "_blank"
        // );
        window.location.href =
          "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802024561";
      } else if (type == "beian_icp") {
        //底部 京ICP备案
        // window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
        window.location.href = "https://beian.miit.gov.cn/#/Integrated/index";
      } else if (type == "agreement") {
        //底部 隐私跳转
        this.$router.push({
          path: "/agreement",
          query: { agreementCode: value },
        }); // 编程式导航到 '/agreement' 页面
      } else if (type == "gongsi") {
        //底部 诚聘英才
        // window.open("https://www.lagou.com/gongsi/70234.html", "_blank");
        window.location.href = "https://www.lagou.com/gongsi/70234.html";
      } else if (type == "mailto") {
        //底部 合作意向,邮箱
        window.location.href = "mailto:hezuo@17shanyuan.com";
      } else if (type == "gongyi") {
        //页面锚点
        document
          .querySelector("#gongyi")
          .scrollIntoView({ behavior: "smooth" });
        // window.location.href = "#gongyi";
      } else if (type == "callPhone") {
        //播打电话
        window.location.href = `tel:${value}`;
      }
    },
  },
  created() {
    that = this;
    //设置标题
    window.document.title = "中益善源";
    //以750为设计稿时，750全屏=7.5rem，用于适配手机端
    // document.documentElement.style.fontSize =
    //   (document.documentElement.clientWidth / 750) * 16 + "px";
    // if (this._isMobile()) {
    //   //手机端
    //   that.resetRem();
    // } else {
    //   //pc端
    //   that.isWidth = 2; //pc端
    // }
    that.resetRem();
    // console.info(
    //   "====document.documentElement.clientHeight",
    //   document.documentElement.clientHeight
    // );
    //如果url参数存在appOpen，则执行参数事件内容。（如：www.17shanyuan.com?appOpen=android_download表示打开官网的同时下载android app）
    this.appOpen(this.$route.query.appOpen)
  },
};
</script>

<style>
.ll_content {
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
}
</style>