<template>
  <!--二维码浮窗组件 , 
        fadeIn: isActive,
        fadeOut: !isActive, !isActive,
        iv_dialog_code_active: isActive,-->
  <div
    class="ll_dialog"
    @mouseenter="selectButton(true)"
    @mouseleave="selectButton(false)"
  >
    <img
      style="z-index: 200"
      :src="imgUrls[imageType]"
      :class="{
        iv_dialog_code: true,
        fadeIn_setting: isStart && isActive,
        fadeOut_setting: isStart && !isActive,
      }"
    />
    <div
      style="z-index: 100"
      ref="button"
      :class="{
        my_dialog_color_bg_select: isActive, //true:选中，false:未选中
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
//组件使用示例
// 	<my-dialog-code imageUrl="/static/images/ic_qrcode_for_mini.jpg"></my-dialog-code>
// imageUrl="/static/images/ic_qrcode_for_ios.png"
// imageUrl="/static/images/ic_qrcode_for_mini.jpg"
export default {
  props: ["imageType", "isShowCode"],
  data() {
    return {
      isStart: false, //只有当start时，缩放动画才能生效。（就是说鼠标放上去才能生效。）
      isActive: false, //是否开始动画
      imgUrls: {
        android: require("../assets/images/ic_qrcode_for_android.jpg"),
        ios: require("../assets/images/ic_qrcode_for_ios.jpg"),
        mini: require("../assets/images/ic_qrcode_for_mini.jpg"),
      },
    };
  },
  methods: {
    selectButton(isActive) {
      if (this.isShowCode) {
        this.isStart = true;
      }
      this.isActive = isActive;
      // console.info("====isShow", isShow);
      // console.info("====imgUrls[imageType]", this.imgUrls[this.imageType]);
      // if (isShow) {
      //   this.animationName = "animation-name:anim_key_true;";
      //   // this.$refs.codeImg.classList.add(item.show);
      // } else {
      //   this.animationName = "animation-name:anim_key_false;";
      // }
      // this.isShow = true;
      // this.isShow = isShow;
    },
  },
};
</script>

<style lang="scss" scoped>
/**选中效果添加黑色遮罩 */
.my_dialog_color_bg_select {
  opacity: 0.7;
  // filter: invert(1);

  // -webkit-filter: grayscale(100%);
  // -moz-filter: grayscale(100%);
  // -ms-filter: grayscale(100%);
  // -o-filter: grayscale(100%);
  // filter: grayscale(100%);
  // -webkit-filter: gray;
  // filter: gray;
  // -webkit-filter: progid:dximagetransform.microsoft.basicimage(grayscale=1);
  // filter: progid:dximagetransform.microsoft.basicimage(grayscale=1);
}
/* 淡入动画 */
.fadeIn_setting {
  /* animation-delay: 0s;  */
  /* animation-delay: 0.3s;   */
  animation-delay: 0s;
  animation-duration: 0.3s; /**执行时间 */
  animation-name: fadeIn;
  animation-fill-mode: forwards; /**让css keyframes动画停在最后一刻 */
}
@keyframes fadeIn {
  from {
    opacity: 0;
    // transform: translatey(140px) scale(0, 0);
    transform: translatey(139px) scale(0, 0);
  }
  to {
    opacity: 1;
    // transform: translateY(0px) scale(1, 1);
    transform: translateY(139px) scale(1, 1);
  }
}

/* 淡出动画 */
.fadeOut_setting {
  /* animation-delay: 0s;  */
  /* animation-delay: 0.3s;   */
  animation-delay: 0s;
  animation-duration: 0.3s; /**执行时间 */
  animation-name: fadeOut;
  animation-fill-mode: forwards; /**让css keyframes动画停在最后一刻 */
}
@keyframes fadeOut {
  from {
    opacity: 1;
    // transform: translateY(0px) scale(1, 1);
    transform: translateY(139px) scale(1, 1);
  }
  to {
    opacity: 0;
    // transform: translateY(140px) scale(0, 0);
    transform: translateY(139px) scale(0, 0);
  }
}
.ll_dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: 150px;
  
  // position: relative;
}
.ll_dialog_code {
  // width: 164px;
  // height: 164px;
  width: 139px;
  height: 139px;
  // margin-top: -169px;
  // top: 0px;
  // margin-top: 0px;
  // padding-top: 0px;
  margin-left: -3px;
}
.iv_dialog_code {
  margin-top: -125px;
  width: 125px;
  height: 125px;
  background: #ffffff;
  box-shadow: 0px 3px 7px 0px rgba(115, 33, 0, 0.3);
  border-radius: 7px;
  border: 7px solid #ffffff;

  opacity: 0;
  // transition: opacity 0.5s ease;
}
.iv_dialog_code_active {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 7px 0px rgba(115, 33, 0, 0.3);
  border-radius: 7px;
  border: 7px solid #ffffff;

  opacity: 1; /* 设置图片显示时的透明度 */
  transition: opacity 0.5s ease;
}
</style>