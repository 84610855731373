<template>
  <!--用户协议-->
  <div
    class="agreement_content" 
    style="fontSize:16px;"
  >
    <div class="agreement_title">{{ title }}</div>
    <div class="agreement_content_child">
      <div v-for="item in content" :key="item">
        <div v-if="item && item.type == 'text'">
          <div v-for="item_2 in item.value" :key="item_2">
            <div v-html="item_2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script >
import axios from "axios";
export default {
  data() {
    return {
      agreementCode: 100,
      title: "", //协议弹窗标题
      content: [], //需要解析的协议内容
    };
  },
  created() {
    //该页面不随着屏幕的尺寸改变而改变，所以重置fontSize
    document.documentElement.style.fontSize = 16 + "px";

    this.agreementCode = this.$route.query.agreementCode;
    // console.info("==this.agreementCode", this.agreementCode);
    var that = this;
    var url =
      this.$getApiHost() +
      "/sy3_cms/agreement/user/detail?agreementCode=" +
      this.agreementCode;
    // console.info("url", url);
    axios
      .get(url, {
        // params: {
        // },
      })
      .then(function (sres) {
        // console.info("== requestUpdateInfo succ res", sres);
        if (sres.data.code == "200") {
          that.content = JSON.parse(sres.data.data.agreementDetail.content);
          if (sres.data.data.agreementDetail.title) {
            that.title = sres.data.data.agreementDetail.title;
          }
        }
        // console.info("==that.content", that.content);
        // console.info("==that.title", that.title);
      })
      .catch(function (err) {
        // that.$myLog("== catch ", err);
        console.info("== requestUpdateInfo fail err", err);
      });
  },
};
</script>
<style lang="scss" scoped>
.agreement_content {
  z-index: 1000;
  display: flex;
  background: #ffffff;
  /*scroll-view 横向滑动，需要设置成display: inline-block; 父布局要加white-space: nowrap;*/
  flex-direction: column;
  /*元素排列方向【row：横向排列】【column：纵向排列】*/
  justify-content: left;
  /*水平对齐方式【left：从左到右】【space-between：两端对齐】【center：居中】*/
  align-items: center;
  /*垂直对方方式【flex-start：从上到下】【center：居中】*/
  text-align: left; //文字居左

  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  .agreement_title {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 30px;
  }
  .agreement_content_child {
    max-width: 960px;
  }
}
</style>