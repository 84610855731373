<template>
  <div
    :class="{
      p: true,
      css_big: isShow,
    }"
    ref="refName"
    @mouseenter="selectButton(true)"
    @mouseleave="selectButton(false)"
  >
    <slot></slot>
  </div>
</template>

<script>
// :class="{
//       anim_select_true_setting: isStart && isShow,
//       anim_select_false_setting: isStart && !isShow,
//     }"
// <!--使用示例-->
// <my-animation-select>使用示例</my-animation-select>
export default {
  props: ["color"],
  data() {
    return {
      isStart: false, //只有当start时，缩放动画才能生效。（就是说鼠标放上去才能生效。）
      isShow: false, //true:需要缩放，false:不需要缩放
    };
  },
  methods: {
    selectButton(isShow) {
      this.isStart = true; //暂时不要动画了
      this.isShow = isShow;
      //   if (isShow) {
      //     this.$refs.refName.step(
      //       {
      //         scale: [1.2, 1.2],
      //       },
      //       {
      //         timingFunction: "ease-in",
      //         duration: 100,
      //       }
      //     );
      //     this.$refs.refName.run(() => {
      //       // console.log('动画执行完毕1')
      //     });
      //   } else {
      //     this.$refs.refName.step(
      //       {
      //         scale: [1, 1],
      //       },
      //       {
      //         timingFunction: "ease",
      //         duration: 300,
      //       }
      //     );
      //     this.$refs.refName.run(() => {
      //       // console.log('动画执行完毕2')
      //     });
      //   }
    },
  },
};
</script>

<style lang="scss">

.p {
  text-decoration: none;

  font-weight: 300;
}
.p:hover {
  text-decoration: underline; /* 下划线 */
  // text-decoration-color: #ffffff; /* 下划线颜色 */
  text-decoration-style: inherit; /* 下划线样式 */
  text-decoration-thickness: px; /* 下划线粗细 */
 
  // text-shadow: 0 0 1px #ffffff;

  font-weight: 600;
}
/**pc端按钮线 */
.anim_select_line_true_setting {
  border-bottom: 1px solid black;
  box-shadow: 0 -1px 0 0 solid black;
  /* animation-delay: 0s;  */
  /* animation-delay: 0.3s;   */
  // animation-delay: 0s;
  // animation-duration: 0.3s; /**执行时间 */
  // animation-name: anim_select_line_true;
  // animation-fill-mode: forwards; /**让css keyframes动画停在最后一刻 */
}
@keyframes anim_select_line_true {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

.anim_select_line_false_setting {
  border-bottom: 1px solid #00000000;
  box-shadow: 0 -1px 0 0 solid #00000000;
  /* animation-delay: 0s;  */
  /* animation-delay: 0.3s;   */
  // animation-delay: 0s;
  // animation-duration: 0.3s; /**执行时间 */
  // animation-name: anim_select_line_false;
  // animation-fill-mode: forwards; /**让css keyframes动画停在最后一刻 */
}
@keyframes anim_select_line_false {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
/**pc端按钮缩放 */
.anim_select_true_setting {
  /* animation-delay: 0s;  */
  /* animation-delay: 0.3s;   */
  animation-delay: 0s;
  animation-duration: 0.3s; /**执行时间 */
  animation-name: anim_select_true;
  animation-fill-mode: forwards; /**让css keyframes动画停在最后一刻 */
}
@keyframes anim_select_true {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

.anim_select_false_setting {
  /* animation-delay: 0s;  */
  /* animation-delay: 0.3s;   */
  animation-delay: 0s;
  animation-duration: 0.3s; /**执行时间 */
  animation-name: anim_select_false;
  animation-fill-mode: forwards; /**让css keyframes动画停在最后一刻 */
}
@keyframes anim_select_false {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>