import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'

import Index from './components/my-index.vue'
import Agreement from './components/my-dialog-agreement.vue'

// 定义路由配置
const routes = [
    { path: '/', component: Index,
      redirect: '/index.html',  //为了做waf防篡改，需要指定一个以.html结尾的页面
    },
    { path: '/agreement', component: Agreement },
    { path: '/index.html', component: Index },
//    { path: '/boc/index.php', component: Index },
    // { path: '/boc/index.php', redirect: '/' },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
})

const app = createApp(App)

//添加配置信息
//业务域名对应的服务器域名 
var mapAip = {
    // 'http://192.168.1.199:8080': 'https://apit.17shanyuan.com/1.0',
    'https://t2.17shanyuan.com': 'https://apit.17shanyuan.com/1.0',
    'https://pre-www.17shanyuan.com': 'https://pre-api.17shanyuan.com',
    'https://www.17shanyuan.com': 'https://api.17shanyuan.com',
}
var apiHost = mapAip[window.location.origin]; //当前的服务器域名 



if (!apiHost) { //不存在的时候，使用生产环境
    apiHost = 'https://api.17shanyuan.com';
}

// console.log('apiHost', apiHost)
app.config.globalProperties.$getApiHost = () => { //使用this.$getApiHost()
    return apiHost
}

//显示build打包时间
// function formatBuildTime(timestamp) {
//     const date = new Date(timestamp);
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, '0');
//     const day = String(date.getDate()).padStart(2, '0');
//     const hours = String(date.getHours()).padStart(2, '0');
//     const minutes = String(date.getMinutes()).padStart(2, '0');
//     const seconds = String(date.getSeconds()).padStart(2, '0');
//     return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
// }
// console.log('process.env.VUE_APP_BUILD_TIMESTAMP', process.env.VUE_APP_BUILD_TIMESTAMP);
// console.log('buildTime', formatBuildTime(process.env.VUE_APP_BUILD_TIMESTAMP));
// const dotenv = require('dotenv');
// dotenv.config({ path: __dirname + '/.env' });
// console.log(process.env.VUE_APP_Version);



//创建vue
app.use(router).mount('#app')