<template>
  <!-- 在此处添加 <router-view> 来展示当前路由对应的组件 -->
  <router-view> </router-view>
</template>

<script> 

export default {
  name: "App",
  data() {
    return {};
  },
  components: {},
  methods: {},
  created() {
    //设置标题
    window.document.title = "中益善源";
    // console.log(this.$getApiHost1);
  },
  watch: {
    // $route(to) {
    //   // 如果当前路由是首页，则不应用动画
    //   console.info("==to", to);
    //   if (to.path === "/") {
    //     this.animationName = null; // 不使用任何动画
    //   } else {
    //     this.animationName = "slide-right"; // 使用 'fade' 动画
    //   }
    // },
  },
};
</script>

<style>
/**
阿里云字体库地址： 
https://www.iconfont.cn/fonts/index?spm=a313x.fonts_index.i1.2.48093a810wSsLX&type=3
文字压缩工具：
https://blog.csdn.net/qq_41619796/article/details/126021771
*/
@font-face {
  /* font-family:AlimamaDaoLiTi ;
  src: url("assets/AlimamaDaoLiTi.ttf"); */
  font-family: Alibaba_PuHuiTi_P3;
  src: url("assets/Alibaba_PuHuiTi_P3.ttf");
}
#app {
  /* font-family:  sans-serif,Avenir, Helvetica, Arial; */
  /* font-family: "haipai.ttf";  
  src: url("assets/haipai.ttf");  */
  font-family: Arial, sans-serif, Avenir, Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
