<template>
  <!--下载提示框，全屏-->
  <div>
    <div @click.stop="clickOpen()">
      <slot></slot>
    </div>
    <div v-if="isStart" ref="download">
      <div class="ll_download_info" @click.stop="clickClose()">
        <div
          :class="{
            ll_show_dialog_share_info: true,
            anim_download_true_setting: isShow,
            anim_download_false_setting: !isShow,
          }"
          @touchmove.prevent
        >
          <img
            class="iv_share_1"
            src="../assets/images/ic_activity_arrow.png"
          />
          <div class="tv_share_2">
            请点击右上角选择“
            <div class="tv_share_3" style="fontsize: 16px">在浏览器打开</div>
            ”
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// <!--弹窗方式-->
// <MyDialogDownloadInfo v-on:appOpen="clickItem">
//   <div>Android下载</div>
// </MyDialogDownloadInfo>

export default {
  props: ["clickType"],
  data() {
    return {
      isStart: false, //只有当start时，缩放动画才能生效。（就是说鼠标放上去才能生效。）
      isShow: false, //true:显示下载指引，false:关闭下载指引
    };
  },
  methods: {
    clickClose() {
      //   this.$refs.download.close();
      this.isShow = false;
      setTimeout(() => {
        this.isStart = false;
      }, 300);
    },
    clickOpen() {
      if (navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1) {
        //在微信环境，显示指引通过手机浏览器打开下载android app
        // this.$refs.download.open("center");
        this.isStart = true;
        this.isShow = true;
      } else {
        // getApp().appOpen("android_download");
        // this.isShow = true;
        this.$emit("clickItem", this.clickType);
      }
    },
  },
};
</script>

<style lang="scss">
.anim_download_true_setting {
  /* animation-delay: 0s;  */
  /* animation-delay: 0.3s;   */
  animation-delay: 0s;
  animation-duration: 0.3s; /**执行时间 */
  animation-name: anim_download_true;
  animation-fill-mode: forwards; /**让css keyframes动画停在最后一刻 */
}
@keyframes anim_download_true {
  0% {
    opacity: 0;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.anim_download_false_setting {
  /* animation-delay: 0s;  */
  /* animation-delay: 0.3s;   */
  animation-delay: 0s;
  animation-duration: 0.3s; /**执行时间 */
  animation-name: anim_download_false;
  animation-fill-mode: forwards; /**让css keyframes动画停在最后一刻 */
}
@keyframes anim_download_false {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

.ll_download_info {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  /* 确保弹出窗口在其它元素之上 */
  // background: #ff0000;

  .ll_show_dialog_share_info {
    width: 100%;
    height: 100%;
    // background:#ff0000;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    display: flex;
    /*scroll-view 横向滑动，需要设置成display: inline-block; 父布局要加white-space: nowrap;*/
    flex-direction: column;
    /*元素排列方向【row：横向排列】【column：纵向排列】*/
    justify-content: left;
    /*水平对齐方式【left：从左到右】【space-between：两端对齐】【center：居中】*/
    align-items: center;
    /*垂直对方方式【flex-start：从上到下】【center：居中】*/

    z-index: 1000;

    .iv_share_1 {
      width: 3rem;
      height: 3rem;
      margin-left: 3rem;
      margin-top: 0.5rem;
    }

    .tv_share_2 {
      margin-top: 0.05rem;
      font-size: 0.3rem;
      font-weight: 300;
      color: #333333;
      padding-left: 0.3rem;
      padding-top: 0.2rem;
      padding-right: 0.3rem;
      padding-bottom: 0.2rem;

      background: #ffffff;
      box-shadow: 0px 0.1rem 0.2rem 0rem rgba(0, 0, 0, 0.05);
      border-radius: 0.46rem;

      font-family: Arial, sans-serif, Avenir, Helvetica;
      .tv_share_3 {
        font-weight: 700;
        font-size: 0.32rem;
        display: inline-block;
      }
    }
  }
}
</style>